<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-container>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="title"
            >
              <v-text-field
                label="Title"
                name="title"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.title"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="client_id"
            >
              <v-text-field
                label="Client ID"
                name="client_id"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.client_id"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="client_secret"
            >
              <v-text-field
                label="Client Secret"
                name="client_secret"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.client_secret"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="NPI"
            >
              <v-text-field
                label="NPI"
                name="NPI"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.NPI"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row v-for="(item, index) in formModel.additionalValues" :key="index">
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true }"
              :name="`name_${index}`"
            >
              <v-text-field
                label="Name"
                :name="`name_${index}`"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="item.name"
                :disabled="item.is_disabled"
                :ref="`name_${index}`"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :vid="`value_${index}`"
            >
              <v-text-field
                label="Value"
                :name="`value_${index}`"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                :disabled="item.is_disabled"
                v-model="item.value"
              />
            </validation-provider>
          </v-col>
          <v-col cols="3" class="d-flex align-center">
            <v-checkbox
              :name="`is_encrypted_${index}`"
              :hide-details="false"
              :disabled="item.is_disabled"
              v-model="item.is_encrypted"
            >
              <template #label>
                <p class="text-body-2 ma-0">Encrypt Value</p>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="1" class="d-flex align-center">
            <v-btn icon color="error" @click="removeItem(index)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-btn color="primary" @click="addItem" class="ml-3"> Add </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn plain class="mr-2" @click="onCancel" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "PverifyForm",
  data() {
    return {
      formModel: {
        title: "",
        credentialsList: {
          client_id: "",
          client_secret: "",
          NPI: "",
        },
        additionalValues: [
          {
            name: "",
            value: "",
            is_encrypted: false,
          },
        ],
      },
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;
      const credentialListKeys = Object.keys(this.formModel.credentialsList);

      this.formModel = {
        title: name,
        ...credentials.reduce(
          (acc, item) => {
            if (credentialListKeys.includes(item.name)) {
              acc.credentialsList[item.name] = item.value;
            } else {
              acc.additionalValues.push({
                ...item,
                is_disabled: item.is_encrypted,
              });
            }

            return acc;
          },
          { credentialsList: {}, additionalValues: [] }
        ),
      };
    }
  },
  methods: {
    onSubmit() {
      const payload = {
        group_name: this.formModel.title,
        credentials_list: Object.entries(this.formModel.credentialsList)
          .map(([key, value]) => {
            if (key === "client_secret") {
              return {
                name: key,
                value: value,
                is_encrypted: true,
              };
            }

            return {
              name: key,
              value: value,
              is_encrypted: false,
            };
          })
          .concat(this.formModel.additionalValues),
      };

      this.$emit("submit-form", payload);
    },
    addItem() {
      this.formModel.additionalValues.push({
        name: "",
        value: "",
        is_encrypted: false,
      });
    },
    removeItem(index) {
      this.formModel.additionalValues.splice(index, 1);
    },
    onCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.additonal-fields {
  font-size: 14px;

  span {
    font-size: 13px;
    font-style: italic;
  }
}
</style>
