<template>
    <validation-observer v-slot="{ handleSubmit, valid }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="title"
            >
              <v-text-field
                label="Title"
                name="title"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.title"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="host"
            >
              <v-text-field
                label="Host"
                name="host"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.host"
              />
            </validation-provider>
          </v-col>

          <v-col cols="3">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="port"
            >
              <v-text-field
                label="Port"
                name="port"
                type="number"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.port"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="username"
            >
              <v-text-field
                label="Username"
                name="username"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.username"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-select
              v-model="keyType"
              :items="keyTypes"
              label="Passkey Type"
              outlined
            />
          </v-col>
          <v-col cols="8" v-if="keyType === 'password'" key="password">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="password"
            >
              <v-text-field
                label="Password"
                name="password"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.password"
              />
            </validation-provider>
          </v-col>
          <v-col cols="8" v-if="keyType === 'ssh'" key="privateKey">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="privateKey"
            >
              <v-textarea
                label="Private Key"
                name="privateKey"
                :placeholder="privateKeyPlaceholder"
                flat
                outlined
                :hide-details="false"
                :error="errors.length > 0"
                :error-count="errors.length"
                :error-messages="errors"
                v-model="formModel.credentialsList.privateKey"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn
              plain
              class="mr-2"
              @click="onCancel"
              :disabled="loading"
            >
              Cancel
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              :disabled="!valid || loading"
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
</template>

<script>
export default {
  name: 'SFTPForm',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formModel: {
        title: '',
        credentialsList: {
          host: '',
          port: '',
          username: '',
          password: '',
          privateKey: '',
        },
      },
      keyTypes: ['password', 'ssh'],
      keyType: 'password',
      privateKeyPlaceholder: `
        BEGIN PRIVATE KEY
        content...
        END PRIVATE KEY
      `,
    };
  },
  watch: {
    keyType() {
      if (this.keyType === 'password') {
        this.formModel.credentialsList.privateKey = '';
      } else {
        this.formModel.credentialsList.password = '';
      }
    },
  },
  methods: {
    onSubmit() {
      const payload = {
        group_name: this.formModel.title,
        credentials_list: Object.entries(this.formModel.credentialsList)
          .reduce((acc, [key, value]) => {
            if ((key === 'password' || key === 'privateKey')) {
              if (value) {
                return acc.concat({
                  name: key,
                  value: value,
                  is_encrypted: true,
                });
              } else {
                return acc;
              }
            }

            return acc.concat({
              name: key,
              value: value,
              is_encrypted: false,
            });
          }, []),
      };

      this.$emit('submit-form', payload);
    },
    onCancel() {
      this.$emit('close');
    }
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;
      const credentialListKeys = Object.keys(this.formModel.credentialsList);

      this.formModel = {
        title: name,
        credentialsList: credentials.reduce((acc, item) => {
          if (item.name === 'password') {
            this.keyType = 'password';
          }

          if (item.name === 'privateKey') {
            this.keyType = 'ssh';
          }

          if (credentialListKeys.includes(item.name)) {
            acc[item.name] = item.value;
          }

          return acc;
        }, {}),
      };


    }
  },
}
</script>
