<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="title"
          >
            <v-text-field
              label="Title"
              name="title"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.title"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <validation-provider v-slot="{ errors }" rules="required" name="host">
            <v-text-field
              label="Host"
              name="host"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.host"
            />
          </validation-provider>
        </v-col>

        <v-col cols="3">
          <validation-provider v-slot="{ errors }" rules="required" name="port">
            <v-text-field
              label="Port"
              name="port"
              type="number"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.port"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <validation-provider v-slot="{ errors }" rules="required" name="user">
            <v-text-field
              label="User"
              name="user"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.user"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="database"
          >
            <v-text-field
              label="Database"
              name="database"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.database"
            />
          </validation-provider>
        </v-col>

        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="database"
          >
            <v-select
              item-text="name"
              item-value="value"
              name="dialect"
              label="Dialect"
              outlined
              :items="dialects"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.dialect"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="password"
          >
            <v-text-field
              label="Password"
              name="password"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.password"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-for="(item, index) in formModel.additionalValues" :key="index">
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true }"
            :name="`name_${index}`"
          >
            <v-text-field
              label="Name"
              :name="`name_${index}`"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="item.name"
              :disabled="item.is_disabled"
              :ref="`name_${index}`"
            />
          </validation-provider>
        </v-col>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :vid="`value_${index}`"
          >
            <v-text-field
              label="Value"
              :name="`value_${index}`"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              :disabled="item.is_disabled"
              v-model="item.value"
            />
          </validation-provider>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-checkbox
            :name="`is_encrypted_${index}`"
            :hide-details="false"
            :disabled="item.is_disabled"
            v-model="item.is_encrypted"
          >
            <template #label>
              <p class="text-body-2 ma-0">Encrypt Value</p>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="1" class="d-flex align-center">
          <v-btn icon color="error" @click="removeItem(index)">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col>
          <v-btn text @click="addItem" color="primary" small> + Add </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn plain class="mr-2" @click="onCancel" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "DatabaseForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formModel: {
        title: "",
        credentialsList: {
          host: "",
          port: "",
          user: "",
          password: "",
          database: "",
          dialect: "postgres",
        },
        additionalValues: [
          {
            name: "",
            value: "",
            is_encrypted: false,
          },
        ],
      },
      dialects: [
        { name: "Postgres", value: "postgres" },
        { name: "Microsoft SQL Server", value: "mssql" },
        { name: "RedShift", value: "redshift" },
      ],
    };
  },
  methods: {
    onSubmit() {
      const payload = {
        group_name: this.formModel.title,
        credentials_list: Object.entries(this.formModel.credentialsList)
          .map(([key, value]) => {
            if (key === "password") {
              return {
                name: key,
                value: value,
                is_encrypted: true,
              };
            }

            return {
              name: key,
              value: value,
              is_encrypted: false,
            };
          })
          .concat(this.formModel.additionalValues),
      };

      this.$emit("submit-form", payload);
    },
    addItem() {
      this.formModel.additionalValues.push({
        name: "",
        value: "",
        is_encrypted: false,
      });
    },
    removeItem(index) {
      this.formModel.additionalValues.splice(index, 1);
    },
    onCancel() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;
      const credentialListKeys = Object.keys(this.formModel.credentialsList);

      this.formModel = {
        title: name,
        ...credentials.reduce(
          (acc, item) => {
            if (credentialListKeys.includes(item.name)) {
              acc.credentialsList[item.name] = item.value;
            } else {
              acc.additionalValues.push({
                ...item,
                is_disabled: item.is_encrypted,
              });
            }

            return acc;
          },
          { credentialsList: {}, additionalValues: [] }
        ),
      };
    }
  },
};
</script>
