<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="title"
          >
            <v-text-field
              label="Title"
              name="title"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.group_name"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="sendgridApiKey"
          >
            <v-text-field
              label="Sendgrid API Key"
              name="sendgridApiKey"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.sendgridApiKey"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn plain class="mr-2" @click="onCancel" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "TwilioEmailForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formModel: {
        group_name: "",
        sendgridApiKey: "",
      },
    };
  },
  methods: {
    onSubmit() {
      const payload = {
        ...this.formModel,
        credentials_list: [
          {
            name: "sendgridApiKey",
            value: this.formModel.sendgridApiKey,
            is_encrypted: true,
          },
        ],
      };
      this.$emit("submit-form", payload);
    },
    onCancel() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;

      this.formModel = {
        group_name: name,
        credentials_list: credentials.reduce((acc, item) => {
          return acc.concat({
            ...item,
            is_disabled: item.is_encrypted,
          });
        }, []),
      };
    }
  },
};
</script>
