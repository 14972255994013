<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="title"
          >
            <v-text-field
              label="Title"
              name="title"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.group_name"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="username"
          >
            <v-text-field
              label="Username"
              name="username"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.username"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        v-for="(item, index) in formModel.credentials_list"
        :key="index"
      >
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true }"
            :name="`name_${index}`"
          >
            <v-text-field
              label="Name"
              :name="`name_${index}`"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="item.name"
              :disabled="item.is_disabled"
              :ref="`name_${index}`"
            />
          </validation-provider>
        </v-col>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="`value_${index}`"
          >
            <v-text-field
              label="Value"
              :name="`value_${index}`"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              :disabled="item.is_disabled"
              v-model="item.value"
            />
          </validation-provider>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <validation-provider
            v-slot="{ errors }"
            :rules="requiredIfPasswordRule(index)"
            :name="`is_encrypted_${index}`"
          >
            <v-checkbox
              :name="`is_encrypted_${index}`"
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              :disabled="item.is_disabled || (item.name === 'password' && item.is_encrypted)"
              v-model="item.is_encrypted"
            >
              <template #label>
                <p class="text-body-2 ma-0">Encrypt Value</p>
              </template>
            </v-checkbox>
          </validation-provider>
        </v-col>
        <v-col cols="1" class="d-flex align-center">
          <v-btn
            icon
            color="error"
            @click="removeItem(index)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-btn
            text
            @click="addItem"
            color="primary"
            small
          >
            + Add
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            plain
            class="mr-2"
            @click="onCancel"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: 'BrowserForm',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formModel: {
        group_name: '',
        username: '',
        credentials_list: [
          {
            name: 'password',
            value: '',
            is_encrypted: true,
          },
        ],
      }
    }
  },
  methods: {
    addItem() {
      this.formModel.credentials_list.push({
        name: '',
        value: '',
        is_encrypted: false,
      });
    },
    removeItem(index) {
      const removedItem = this.formModel.credentials_list.splice(index, 1);

      if (removedItem[0].name === 'password') {
        this.formModel.credentials_list.unshift({
          name: 'password',
          value: '',
          is_encrypted: true,
        });
      }
    },
    onSubmit() {
      const payload = {
        ...this.formModel,
        credentials_list: [
          {
            name: 'username',
            value: this.formModel.username,
            is_encrypted: false,
          },
          ...this.formModel.credentials_list,
        ],
      }
      this.$emit('submit-form', payload);
    },
    requiredIfPasswordRule(index) {
      return this.formModel.credentials_list[index].name === 'password' ?
        { required: { allowFalse: false } } :
        {};
    },
    onCancel() {
      this.$emit('close');
    }
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;

      this.formModel = {
        group_name: name,
        username: (credentials.find(item => item.name === 'username') || {}).value || '',
        credentials_list: credentials.reduce((acc, item) => {
          if (item.name === 'username') return acc;

          return acc.concat({ 
            ...item,
            is_disabled: item.is_encrypted,
          });
        }, []),
      };
    }
  }
}
</script>
