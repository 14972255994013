<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="title"
          >
            <v-text-field
              label="Title"
              name="title"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.group_name"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Auto Generated Token"
            readonly
            outlined
            v-model="credential.value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn plain class="mr-2" @click="onCancel" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "ExternalWorkflowActivatorForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formModel: {
        group_name: "",
        credentials_list: [
          {
            name: "Secret Key",
            value: null,
            is_encrypted: false,
            auto_generated: true,
          },
        ],
      },
      credential: {
        name: "Auto Generated Token",
        value: null,
        is_encrypted: false,
        auto_generated: true,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit-form", {
        ...this.formModel,
        credentials_list: [this.credential],
      });
    },
    onCancel() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;
      this.formModel = {
        group_name: name,
      };
      Object.assign(this.credential, credentials[0]);
    }
  },
};
</script>
