<template>
    <validation-observer v-slot="{ handleSubmit, valid }">
        <form @submit.prevent="handleSubmit(onSubmit)">
            <v-row>
                <v-col cols="12">
                    <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="title"
                    >
                        <v-text-field
                                label="Title"
                                name="title"
                                flat
                                outlined
                                :hide-details="false"
                                :error="errors.length > 0"
                                :error-count="errors.length"
                                :error-messages="errors"
                                v-model="formModel.title"
                        />
                    </validation-provider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9">
                    <validation-provider v-slot="{ errors }" rules="required" name="host">
                        <v-text-field
                                label="Access Key"
                                name="accessKey"
                                flat
                                outlined
                                :hide-details="false"
                                :error="errors.length > 0"
                                :error-count="errors.length"
                                :error-messages="errors"
                                v-model="formModel.credentialsList.accessKey"
                        />
                    </validation-provider>
                </v-col>

                <v-col cols="3">
                    <validation-provider v-slot="{ errors }" rules="required" name="port">
                        <v-text-field
                                label="Secret ID"
                                name="secretKey"
                                flat
                                outlined
                                :hide-details="false"
                                :error="errors.length > 0"
                                :error-count="errors.length"
                                :error-messages="errors"
                                v-model="formModel.credentialsList.secretKey"
                        />
                    </validation-provider>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <validation-provider v-slot="{ errors }" rules="required" name="user">
                        <v-text-field
                                label="Region"
                                name="region"
                                flat
                                outlined
                                :hide-details="false"
                                :error="errors.length > 0"
                                :error-count="errors.length"
                                :error-messages="errors"
                                v-model="formModel.credentialsList.region"
                        />
                    </validation-provider>
                </v-col>
            </v-row>

          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" rules="required" name="user">
                <v-text-field
                    label="Account ID"
                    name="accountId"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formModel.credentialsList.accountId"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
                <v-col class="d-flex justify-end">
                    <v-btn plain class="mr-2" @click="onCancel" :disabled="loading">
                        Cancel
                    </v-btn>
                    <v-btn
                            type="submit"
                            color="primary"
                            :disabled="!valid || loading"
                            :loading="loading"
                    >
                        Submit
                    </v-btn>
                </v-col>
            </v-row>
        </form>
    </validation-observer>
</template>

<script>
export default {
    name: "AWSForm",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        credentialToEdit: {
            type: Object,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formModel: {
                title: "",
                credentialsList: {
                    accessKey: "",
                    secretKey: "",
                    region: "",
                    accountId: "",
                },
            }
        };
    },
    methods: {
        onSubmit() {
            const payload = {
                group_name: this.formModel.title,
                credentials_list: Object.entries(this.formModel.credentialsList)
                    .map(([key, value]) => {
                        if (key === "accessKey" || key === "secretKey") {
                            return {
                                name: key,
                                value: value,
                                is_encrypted: true,
                            };
                        }

                        return {
                            name: key,
                            value: value,
                            is_encrypted: false,
                        };
                    }),
            };
            this.$emit("submit-form", payload);
        },
        onCancel() {
            this.$emit("close");
        },
    },
    mounted() {
        if (this.isEdit) {
            const { name, credentials } = this.credentialToEdit;
            const credentialListKeys = Object.keys(this.formModel.credentialsList);

            this.formModel = {
                title: name,
                ...credentials.reduce(
                    (acc, item) => {
                        if (credentialListKeys.includes(item.name)) {
                            acc.credentialsList[item.name] = item.value;
                        } else {
                            acc.additionalValues.push({
                                ...item,
                                is_disabled: item.is_encrypted,
                            });
                        }

                        return acc;
                    },
                    { credentialsList: {}, additionalValues: [] }
                ),
            };
        }
    },
};
</script>
