<template>
  <div class="Authentications">
    <v-row class="Authentications__filter-row">
      <v-col cols="auto" class="mr-auto">
        <v-select
          v-model="selectedAuthFilter"
          :items="authFilters"
          item-text="name"
          item-value="value"
          label="Authentication Type"
          outlined
          dense
        />
      </v-col>
      <v-col cols="auto">
        <v-menu offset-y open-on-hover bottom right>
          <template #activator="{ on, attrs }">
            <v-btn small color="primary" v-on="on" v-bind="attrs">
              + Add new Credentials
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class=""
              v-for="(authItem, index) in authTypes"
              :key="index"
              @click="openAuthenticationModal(authItem.value)"
            >
              <v-list-item-title>{{ authItem.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      class="Authentications__data-table"
      :headers="headers"
      :items="authentications"
      :expanded.sync="expanded"
      :custom-filter="filterBasedOnType"
      :search="selectedAuthFilter"
      item-key="id"
      outlined
      show-expand
      single-expand
      :loading="fetchCredentialsLoading"
    >
      <template #item.credential_type="{ item }">
        <v-chip small>
          <v-icon left>
            {{ getCredentialIcon(item) }}
          </v-icon>
          {{ getCredentialType(item) }}
        </v-chip>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          icon
          color="primary"
          @click.stop="openAuthenticationModal(item.credential_type, item)"
        >
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon color="error" @click.stop="openDeleteCredentialModal(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card tile flat class="">
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="3"
                  v-for="(credential, index) in item.credentials"
                  :key="index"
                >
                  <v-card flat class="pa-2 Authentications__credential-card">
                    <p class="Authentications__label text-subtitle-2">
                      {{ credential.name }}
                    </p>
                    <p class="Authentications__value text-body-2">
                      {{ credential.value }}
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </v-data-table>

    <action-prompt
      v-model="showDeleteCredentialModal"
      v-if="showDeleteCredentialModal"
      action-text="Delete"
      :action="deleteAuthenticationMethod"
      :loading="deleteCredentialsLoading"
      header="Delete Credential"
      body="Are you sure you want to delete this credential?"
      action-type="error"
    />

    <add-edit-credentials-modal
      v-model="showAddEditCredentialsModal"
      v-if="showAddEditCredentialsModal"
      :credential-type="selectedCredentialType"
      :credential-to-edit="selectedCredentialToEdit"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';

import AddEditCredentialsModal from './modals/add-edit-credentials-modal/add-edit-credentials-modal.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: credentialsGetters,
  mapActions: credentialsActions,
} = createNamespacedHelpers('credentials');

export default {
  name: 'Authentications',
  components: {
    ActionPrompt,
    AddEditCredentialsModal,
  },
  data() {
    return {
      authFilters: [
        {
          name: 'All Authentications',
          value: 'all',
        },
        {
          name: 'Database Connection',
          value: 'database',
        },
        {
          name: 'Browser Authentication',
          value: 'browser',
        },
        {
          name: 'API Token',
          value: 'apiToken',
        },
        {
          name: 'SFTP Connection',
          value: 'sftp',
        },
        {
          name: 'Google Services',
          value: 'google',
        },
        {
          name: 'Microsoft 365 Services',
          value: 'outlook',
        },
        {
          name: 'pVerify API',
          value: 'pVerify',
        },
        {
          name: 'Twilio API',
          value: 'twilio',
        },
        {
          name: 'Sendgrid Email',
          value: 'sendgrid',
        },
        {
          name: 'External Workflow Activator',
          value: 'externalWorkflowActivator',
        },
        {
          name: 'AWS',
          value: 'aws',
        },
      ],
      selectedAuthFilter: 'all',
      headers: [
        { text: 'Name', value: 'name', filterable: false },
        { text: 'Credential Type', value: 'credential_type' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
          filterable: false,
        },
        { text: '', value: 'data-table-expand', filterable: false },
      ],
      credentialIcons: {
        database: 'mdi-database',
        browser: 'mdi-monitor',
        apiToken: 'mdi-api',
        sftp: 'mdi-file-move',
        google: 'mdi-google',
        outlook: 'mdi-outlook',
        pVerify: 'mdi-account-settings-outline',
        externalWorkflowActivator: 'mdi-account-settings-outline', // change me
        aws: 'mdi-aws'
      },
      expanded: [],
      showDeleteCredentialModal: false,
      showAddEditCredentialsModal: false,
      selectedCredentialId: '',
      selectedCredentialToEdit: {},
      selectedCredentialType: '',
    };
  },
  methods: {
    ...credentialsActions(['fetchAuthentications', 'deleteAuthentication']),
    getCredentialType({ credential_type }) {
      return this.authFilters.find((filter) => filter.value === credential_type)
        ?.name;
    },
    getCredentialIcon({ credential_type }) {
      return this.credentialIcons[credential_type];
    },
    openDeleteCredentialModal({ id }) {
      this.selectedCredentialId = id;
      this.showDeleteCredentialModal = true;
    },
    async deleteAuthenticationMethod() {
      await this.deleteAuthentication({ id: this.selectedCredentialId });
      this.selectedCredentialId = '';
      await this.fetchAuthentications();
    },
    openAuthenticationModal(credentialType, credential = {}) {
      this.selectedCredentialType = credentialType;
      this.selectedCredentialToEdit = { ...credential };
      this.showAddEditCredentialsModal = true;
    },
    filterBasedOnType(value, search, item) {
      return search === 'all' || item.credential_type === search;
    },
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchAuthentications();
    },
  },
  computed: {
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
      deleteCredentialsLoading: 'DELETE_CREDENTIALS_LOADING',
      fetchCredentialsLoading: 'FETCH_CREDENTIALS_LOADING',
    }),
    authTypes() {
      return this.authFilters.slice(1);
    },
  },
  async mounted() {
    await this.fetchAuthentications();
  },
};
</script>

<style lang="scss" scoped>
@import './authentications';
</style>
