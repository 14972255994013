<template>
  <v-dialog v-model="modalOpen" max-width="630px" class="AddEditCredentialsModal" scrollable>
    <v-card outlined>
      <v-card-title class="AddEditCredentialsModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            {{ getHeaderText }}
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="AddEditCredentialsModal__content">
        <component :is-edit="isEdit" :is="getComponent" :loading="loading" :credential-to-edit="credentialToEdit"
          @submit-form="save" @close="close" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import ApiForm from "./forms/api-form/api-form.vue";
import BrowserForm from "./forms/browser-form/browser-form.vue";
import SFTPForm from "./forms/sftp-form/sftp-form.vue";
import DatabaseForm from "./forms/database-form/database-form.vue";
import GoogleForm from "./forms/google-form/google-form.vue";
import OutlookForm from "./forms/outlook-form/outlook-form.vue";
import PverifyForm from "./forms/pverify-form/pverify-form.vue";
import TwilioForm from "./forms/twilio-form/twilio-form.vue";
import TwilioEmailForm from "./forms/twilio-email-form/twilio-email-form.vue";
import ExternalWorkflowActivatorForm from "./forms/external-workflow-activator-form/external-workflow-activator-form.vue";
import AwsForm from "@/views/authentications/modals/add-edit-credentials-modal/forms/aws-form/aws-form.vue";

const { mapGetters: credentialsGetters, mapActions: credentialsActions } =
  createNamespacedHelpers("credentials");
const { mapGetters: notificationsGetters } =
  createNamespacedHelpers("notifications");

export default {
  name: "AddEditCredentialsModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    credentialType: {
      type: String,
      required: true,
    },
    credentialToEdit: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...credentialsGetters({
      createCredentialsLoading: "CREATE_CREDENTIALS_LOADING",
      editCredentialsLoading: "EDIT_CREDENTIALS_LOADING",
    }),
    ...notificationsGetters({ hasError: "HAS_ERROR" }),
    isEdit() {
      return Object.keys(this.credentialToEdit).length > 0;
    },
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getComponent() {
      switch (this.credentialType) {
        case "apiToken":
          return ApiForm;
        case "browser":
          return BrowserForm;
        case "sftp":
          return SFTPForm;
        case "database":
          return DatabaseForm;
        case "google":
          return GoogleForm;
        case "outlook":
          return OutlookForm;
        case "pVerify":
          return PverifyForm;
        case "twilio":
          return TwilioForm;
        case "sendgrid":
          return TwilioEmailForm;
        case "externalWorkflowActivator":
          return ExternalWorkflowActivatorForm;
        case "aws":
          return AwsForm;
        default:
          return {};
      }
    },
    getHeaderText() {
      switch (this.credentialType) {
        case "apiToken":
          return "Add API Token Configuration";
        case "browser":
          return "Add Browser Authentication configuration";
        case "sftp":
          return "Add SFTP Connection Configuration";
        case "database":
          return "Add Database Connection Configuration";
        case "google":
          return "Authorize Google Services";
        case "outlook":
          return "Authorize Microsoft Services";
        case "pVerify":
          return "Add pVerify Payment Configuration";
        case "twilio":
          return "Add Twilio Configuration";
        case "sendgrid":
          return "Add Twilio Sendgrid Configuration";
        case "externalWorkflowActivator":
          return "External Workflow Activator";
        case "aws":
          return "AWS";
        default:
          return "";
      }
    },
    loading() {
      return this.createCredentialsLoading || this.editCredentialsLoading;
    },
  },
  methods: {
    ...credentialsActions(["createAuthentication", "updateAuthentication"]),
    close() {
      this.modalOpen = false;
    },
    save(formValue) {
      if (!this.isEdit) {
        this.createAuthentication({
          ...formValue,
          credential_type: this.credentialType,
        });
      } else {
        this.updateAuthentication({
          ...formValue,
          id: this.credentialToEdit.id,
        });
      }
    },
  },
  watch: {
    loading(value) {
      if (!value && !this.hasError) {
        this.modalOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./add-edit-credentials-modal";
</style>
