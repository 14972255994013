<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="title"
          >
            <v-text-field
              label="Title"
              name="title"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.group_name"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-btn color="primary" :loading="loading" @click="authorizeOutlook">
          Microsoft 365 Login
        </v-btn>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn plain class="mr-2" @click="onCancel" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || (!isEdit && !code) || loading"
            :loading="performingOutlookAction"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import uuidv4 from '@/util/uuid_generator';

const { mapActions: workflowsActions, mapGetters: workflowsGetters } =
  createNamespacedHelpers('workflows/list');
const { mapActions: credsActions } = createNamespacedHelpers('credentials');

export default {
  name: 'OutlookForm',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: null,
      formModel: {
        group_name: '',
      },
      requestURI: '',
    };
  },
  computed: {
    ...workflowsGetters({
      performingOutlookAction: 'PERFORM_OUTLOOK_ACTION',
    }),
  },
  created() {
    const scope =
      'scope=Files.ReadWrite.All Mail.Send offline_access Mail.Read';
    const clientId = `client_id=${process.env.VUE_APP_OUTLOOK_CLIENT_ID}`;
    const redirectURI = `redirect_uri=${process.env.VUE_APP_OUTLOOK_REDIRECT_URI}`;
    const responseType = 'response_type=code';
    const accessType = 'access_type=offline';
    const responseMode = 'response_mode=query';
    let state = uuidv4();
    localStorage.setItem('ou_state', state);
    state = `state=${state}`;
    const qs = `${clientId}&${scope}&${redirectURI}&${responseType}&${accessType}&${responseMode}&${state}`;
    this.requestURI = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${qs}`;
  },
  methods: {
    ...workflowsActions(['outlookAction']),
    ...credsActions(['fetchAuthentications']),
    async authorizeOutlook() {
      const options = 'height=500,width=500,status=yes';
      const popupWindow = window.open(this.requestURI, 'popUpWindow', options);
      const intervalId = setInterval(() => {
        try {
          if (window.location.origin === popupWindow.location.origin) {
            const params = new URLSearchParams(popupWindow.location.search);
            const state = params.get('state');
            const savedState = localStorage.getItem('ou_state');

            if (state !== savedState) {
              this.$store.dispatch(
                'notifications/setError',
                new Error('Invalid State'),
                { root: true }
              );
            }
            this.code = params.get('code');
            clearInterval(intervalId);
            popupWindow.close();
          }
        } catch (e) {
          // catch cross origin exception
        }
      }, 100);
    },
    async onSubmit() {
      if (!this.code && this.isEdit) {
        this.$emit('submit-form', this.formModel);
      } else if (this.code) {
        const data = {
          group_name: this.formModel.group_name,
          code: this.code,
          action: 'get_token',
        };
        if (this.isEdit) {
          data['authenticationId'] = this.credentialToEdit?.id;
        }
        const response = await this.outlookAction(data);
        if (response && response.success) {
          await this.fetchAuthentications();
          this.onCancel();
        }
      }
    },
    onCancel() {
      this.$emit('close');
    },
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;
      this.formModel = {
        group_name: name,
        credentials_list: credentials.map((item) => ({
          ...item,
          is_disabled: item.is_encrypted,
        })),
      };
    }
  },
};
</script>
