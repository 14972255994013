<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="title"
          >
            <v-text-field
              label="Title"
              name="title"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.title"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="accountSid"
          >
            <v-text-field
              label="Account SID"
              name="accountSid"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.accountSid"
            />
          </validation-provider>
        </v-col>

        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="authToken"
          >
            <v-text-field
              label="Auth Token"
              name="authToken"
              flat
              outlined
              :hide-details="false"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              v-model="formModel.credentialsList.authToken"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn plain class="mr-2" @click="onCancel" :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "TwilioForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    credentialToEdit: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formModel: {
        title: "",
        credentialsList: {
          accountSid: "",
          authToken: "",
        },
      },
    };
  },
  methods: {
    onSubmit() {
      const payload = {
        group_name: this.formModel.title,
        credentials_list: Object.entries(this.formModel.credentialsList).reduce(
          (acc, [key, value]) => {
            if (key === "authToken") {
              if (value) {
                return acc.concat({
                  name: key,
                  value: value,
                  is_encrypted: true,
                });
              } else {
                return acc;
              }
            }

            return acc.concat({
              name: key,
              value: value,
              is_encrypted: false,
            });
          },
          []
        ),
      };

      this.$emit("submit-form", payload);
    },
    onCancel() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.isEdit) {
      const { name, credentials } = this.credentialToEdit;
      const credentialListKeys = Object.keys(this.formModel.credentialsList);

      this.formModel = {
        title: name,
        credentialsList: credentials.reduce((acc, item) => {
          if (credentialListKeys.includes(item.name)) {
            acc[item.name] = item.value;
          }

          return acc;
        }, {}),
      };
    }
  },
};
</script>
